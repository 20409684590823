import Dashboard from "views/Pages/Dashboard/Dashboard.jsx"
import LoginPage from "views/Pages/LoginPage.jsx"
import Appointments from "views/Pages/Appointments/index.jsx"
import Users from "views/Pages/Users/index.jsx"
import ForgotPassword from "views/Pages/ForgotPassword.jsx"
import ResetPassword from "views/Pages/ResetPassword.jsx"
import Settings from "views/Pages/Settings/index.jsx"
import Services from "views/Pages/Services/index.jsx"
import LabTechs from "./views/Pages/LabTechs/index.jsx"
import ServiceCentresListView from "./views/Pages/ServiceCentres/index.jsx"
import ServiceCentreView from "./views/Pages/ServiceCentres/ServiceCentreView"
import LabTechDetails from "./views/Pages/LabTechs/LabTechDetails.jsx"
import CreateAppointment from "./views/Pages/Appointments/CreateAppointment.jsx"
import ServiceView from "views/Pages/Services/ServiceView.jsx"
import DropOffLocations from "views/Pages/DropOffLocations/index.jsx"
import DropOffLocationView from "views/Pages/DropOffLocations/DropOffLocationView.jsx"
import PromoCodes from "views/Pages/PromoCodes/index.jsx"
import ServiceLocations from "views/Pages/ServiceLocations/index.jsx"
import ServiceLocationView from "views/Pages/ServiceLocations/ServiceLocationView.jsx"
import CompanyEarnings from "views/Pages/CompanyEarnings/index.jsx"
import Notifications from "views/Pages/Notifications/index.jsx"
import AdminNotifications from "views/Pages/AdminNotifications/index.jsx"
import PlatformEditor from "views/Pages/SystemSettings/PlatformEditor.jsx"
import AppointmentDetails from "views/Pages/Appointments/AppointmentDetails.jsx"
import ChangePassword from "views/Pages/ChangePassword.jsx"
import EditPromocode from "views/Pages/PromoCodes/EditPromocode.jsx"
import Admins from "views/Pages/Admins/index.jsx"
import AdminRegister from "views/Pages/AdminRegister.jsx"
import Feedback from "views/Pages/Feedback/index.jsx"
import UserDetail from "views/Pages/Users/UserDetail.jsx"
import Messaging from "views/Pages/Messaging/index.jsx"
import ServiceReportsView from "views/Pages/ServiceReports/index.jsx"
import CorporateList from "views/Pages/Corporate/index.jsx"
import CorporateProfilePage from "views/Pages/Corporate/CorporateProfilePage.jsx"
import KpiPage from "views/Pages/KPI/index.jsx"
import QuestionnairePage from "views/Pages/Questionnaire/index.jsx"
import ServiceNotesPage from "views/Pages/ServiceNotes/index.jsx"
import MedicalNotesReportsView from "views/Pages/MedicalNotesReports/index.jsx"
import ServiceFolderView from "views/Pages/Services/ServiceFolderView.jsx"

var routes = [
  {
    path: "/dashboard",
    layout: "/admin",
    name: "Dashboard",
    icon: "chart",
    component: Dashboard,
  },
  {
    path: "/app-kpi",
    layout: "/admin",
    name: "App KPI",
    icon: "speed",
    component: KpiPage,
  },
  {
    path: "/company-earnings",
    layout: "/admin",
    name: "Company Earnings",
    icon: "dollar",
    component: CompanyEarnings,
  },
  {
    path: "/appointments",
    layout: "/admin",
    name: "Appointments",
    icon: "calendar",
    component: Appointments,
    exact: true,
  },
  {
    path: "/appointments/:id",
    layout: "/admin",
    name: "Appointment Details",
    icon: "calendar",
    component: AppointmentDetails,
    redirect: true,
    exact: true,
  },
  {
    path: "/appointment/create",
    layout: "/admin",
    name: "Create New Appointment",
    icon: "calendar",
    component: CreateAppointment,
    redirect: true,
    exact: true,
  },
  {
    path: "/appointment/edit",
    layout: "/admin",
    name: "Edit Appointment",
    icon: "calendar",
    component: CreateAppointment,
    redirect: true,
    exact: true,
  },
  // {
  //   path: "/service-reports",
  //   layout: "/admin",
  //   name: "Service Reports",
  //   icon: "files",
  //   component: ServiceReportsView,
  //   exact: true,
  // },
  // {
  //   path: "/medical-notes",
  //   layout: "/admin",
  //   name: "Medical Notes",
  //   icon: "files",
  //   component: MedicalNotesReportsView,
  //   exact: true,
  // },
  {
    path: "/users",
    layout: "/admin",
    name: "Users",
    icon: "users",
    component: Users,
    exact: true,
  },
  {
    path: "/users/:id",
    layout: "/admin",
    name: "User Detail",
    icon: "users",
    component: UserDetail,
    redirect: true,
    exact: true,
  },
  {
    path: "/corporate",
    layout: "/admin",
    name: "Corporate Profiles",
    icon: "corporate",
    component: CorporateList,
    exact: true,
  },
  {
    path: "/corporate/:id",
    layout: "/admin",
    name: "Corporate Profile",
    icon: "corporate",
    component: CorporateProfilePage,
    redirect: true,
    exact: true,
  },
  {
    path: "/lab-techs",
    layout: "/admin",
    name: "Service Provider",
    icon: "nurse",
    component: LabTechs,
  },
  {
    path: "/lab-techs-details/:id",
    layout: "/admin",
    name: "Lab Techs Details",
    component: LabTechDetails,
    redirect: true,
  },
  {
    path: "/admins",
    layout: "/admin",
    name: "Admins",
    icon: "admin",
    component: Admins,
  },
  {
    path: "/messaging",
    layout: "/admin",
    name: "Messaging",
    icon: "chat",
    component: Messaging,
  },
  {
    path: "/service-centres",
    layout: "/admin",
    name: "Service Centres",
    icon: "building",
    exact: true,
    component: ServiceCentresListView,
  },
  {
    path: "/service-centres/:id",
    layout: "/admin",
    name: "Service Centre Details",
    icon: "building",
    component: ServiceCentreView,
    redirect: true,
    exact: true,
  },
  {
    path: "/service-locations",
    layout: "/admin",
    name: "Service Locations",
    icon: "map",
    component: ServiceLocations,
    exact: true,
  },
  {
    path: "/service-locations/:id",
    layout: "/admin",
    name: "Edit Service Location",
    icon: "map",
    component: ServiceLocationView,
    redirect: true,
    exact: true,
  },
  {
    path: "/service-locations/new",
    layout: "/admin",
    name: "Add Service Location",
    icon: "map",
    component: ServiceLocationView,
    redirect: true,
    exact: true,
  },
  {
    path: "/services",
    layout: "/admin",
    name: "Services",
    icon: "pill",
    component: Services,
    exact: true,
  },
  {
    path: "/services/:id",
    layout: "/admin",
    name: "Edit Service",
    icon: "pill",
    component: ServiceView,
    redirect: true,
    exact: true,
  },
  {
    path: "/services/folder/:id",
    layout: "/admin",
    name: "Edit Service Folder",
    icon: "pill",
    component: ServiceFolderView,
    redirect: true,
    exact: true,
  },
  {
    path: "/questionnaire",
    layout: "/admin",
    name: "Questionnaire",
    icon: "question",
    component: QuestionnairePage,
    exact: true,
  },
  {
    path: "/service-notes",
    layout: "/admin",
    name: "Service Notes",
    icon: "pencil-square",
    component: ServiceNotesPage,
    exact: true,
  },
  {
    path: "/drop-off",
    layout: "/admin",
    name: "Drop Off Locations",
    icon: "pin",
    component: DropOffLocations,
    exact: true,
  },
  {
    path: "/drop-off/:id",
    layout: "/admin",
    name: "Drop Off Location",
    icon: "pin",
    component: DropOffLocationView,
    redirect: true,
    exact: true,
  },
  {
    path: "/promo-codes",
    layout: "/admin",
    name: "Promo Codes",
    icon: "tag",
    component: PromoCodes,
  },
  {
    path: "/edit-promocode/:id",
    layout: "/admin",
    name: "Edit promo codes",
    icon: "tag",
    component: EditPromocode,
    redirect: true,
  },
  {
    path: "/feedback",
    layout: "/admin",
    name: "Feedback",
    icon: "help",
    component: Feedback,
  },
  {
    path: "/notifications",
    layout: "/admin",
    name: "Notifications",
    icon: "send",
    component: Notifications,
  },
  {
    path: "/my-notifications",
    layout: "/admin",
    name: "My Notifications",
    icon: "bell",
    component: AdminNotifications,
    redirect: true,
  },
  {
    collapse: true,
    path: "/system-settings",
    name: "System Settings",
    icon: "config",
    state: "openSettings",
    views: [
      {
        path: "/system-settings/terms",
        layout: "/admin",
        name: "Terms Of Service",
        component: PlatformEditor,
      },
      {
        path: "/system-settings/privacy-policy",
        layout: "/admin",
        name: "Privacy Policy",
        component: PlatformEditor,
      },
      {
        path: "/system-settings/about",
        layout: "/admin",
        name: "About The App",
        component: PlatformEditor,
      },
    ],
  },
  {
    path: "/settings",
    layout: "/admin",
    name: "Admin Settings",
    icon: "settings",
    component: Settings,
  },
  {
    path: "/login",
    layout: "/auth",
    name: "Login Page",
    mini: "LP",
    component: LoginPage,
    redirect: true,
  },
  {
    path: "/forgot-password",
    layout: "/auth",
    name: "Forgot Password",
    mini: "FP",
    component: ForgotPassword,
    redirect: true,
  },
  {
    path: "/reset-password",
    layout: "/auth",
    name: "Reset Password",
    mini: "RP",
    component: ResetPassword,
    redirect: true,
  },
  {
    path: "/change-password",
    layout: "/auth",
    name: "Change Password",
    mini: "CP",
    component: ChangePassword,
    redirect: true,
  },
  {
    path: "/admin-invitation",
    layout: "/auth",
    name: "Admin Registration",
    mini: "CP",
    component: AdminRegister,
    redirect: true,
  },
]

if (process.env.REACT_APP_CHAT_ENABLED !== "true") {
  routes = routes.filter((r) => r.path !== "/messaging")
}

export default routes
